import { FadeIn } from "@/components/FadeIn";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import * as reboot_api from "@reboot-dev/reboot-api";
import { useRebootClient } from "@reboot-dev/reboot-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { User, useUser } from "../gen/rbt/cloud/v1alpha1/user/user_rbt_react";
import { useAuthSession } from "./Auth";
import { Column, TwoColumnLayout } from "./ColumnLayout";
import { SignUpForm } from "./SignUpForm";
import blueArc from "/blue-arc.svg";
import blueHalfArc from "/blue-half-arc.svg";
import greenArc from "/green-arc.svg";
import greenPacman from "/green-pacman.svg";
import greenWedge from "/green-wedge.svg";
import mintWedge from "/mint-wedge.svg";

import rebootCloud from "/reboot-cloud.svg";

const Footer = () => {
  return (
    <div className="h-20">
      <Separator />
      <div className="flex justify-between h-full items-center text-sm">
        <div className="flex">
          {/* TODO(riley): add privacy policy and TOS.*/}
          <a href="https://reboot.dev" target="_blank" className="mr-10">
            Reboot, Planet Earth
          </a>
        </div>
        <div>© {new Date().getFullYear()} Reboot</div>
      </div>
    </div>
  );
};

const Header = ({
  userState,
  logoutUrl,
}: {
  userState: User.State | undefined;
  logoutUrl: string | undefined;
}) => {
  return (
    <header className="h-20 flex justify-between items-center mt-20">
      <img src={rebootCloud} />
      <div className="flex items-center">
        <div className="mr-6">
          Status:{" "}
          <span className="font-bold">
            {userState?.status == 0 ? "Waitlisted" : "Active"}
          </span>
        </div>
        <Sheet>
          <SheetTrigger>
            <Avatar className="cursor-pointer">
              <AvatarFallback className="bg-primary text-secondary font-bold">
                {userState?.surveyData?.firstName[0].toUpperCase()}
              </AvatarFallback>
            </Avatar>
          </SheetTrigger>
          <SheetContent>
            <Button asChild variant="secondary">
              <a href={logoutUrl}>Logout</a>
            </Button>
          </SheetContent>
        </Sheet>
      </div>
    </header>
  );
};

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="w-screen min-w-96 flex justify-center items-center">
      <div className="max-w-7xl w-screen mx-10 flex flex-col h-screen justify-between">
        {children}
      </div>
    </div>
  );
};

interface ButtonLinkCardProps {
  title: string;
  description: string;
  buttonText: string;
  linkAddress: string;
}

const ButtonLinkCard = ({
  title,
  description,
  buttonText,
  linkAddress,
}: ButtonLinkCardProps) => {
  return (
    <div className="relative w-64 h-36">
      <div className="text-lg font-bold my-2">{title}</div>
      <div className="font-thin my-2">{description}</div>
      <div className="absolute bottom-0">
        <Button asChild variant="secondary" className="w-48">
          <a href={linkAddress} target="_blank">
            {buttonText}
          </a>
        </Button>
      </div>
    </div>
  );
};

const Body = ({ children }: { children: React.ReactNode }) => {
  return <div className="mb-auto h-10">{children}</div>;
};

export const LoggedInDashboard = ({
  userId,
  userEmail,
  logoutUrl,
}: {
  userId: string;
  userEmail: string;
  logoutUrl: string | undefined;
}) => {
  const { useGet, mutators } = useUser({ id: userId });
  const { response, aborted } = useGet();

  const navigate = useNavigate();

  if (
    aborted !== undefined &&
    !(aborted.error instanceof reboot_api.errors_pb.StateNotConstructed)
  ) {
    navigate(
      `/error?error=${encodeURIComponent(
        `${aborted.error.getType().typeName}: ${aborted.message}`
      )}&from=dashboard1`,
      {
        replace: true,
      }
    );
  }

  useEffect(() => {
    async function signUp() {
      const { aborted } = await mutators.signUp({ email: userEmail });
      if (aborted !== undefined) {
        return navigate(
          `/error?error=${encodeURIComponent(
            JSON.stringify(aborted.error.getType().typeName)
          )}&from=dashboard2`,
          {
            replace: true,
          }
        );
      }
    }

    signUp();
  }, []);

  if (response == undefined) return <></>;

  if (response.userState?.surveyData == undefined) {
    return (
      <FadeIn durationMilliseconds={1000}>
        <Layout>
          <Body>
            <img
              src={greenPacman}
              className="absolute left-1/2 transform -translate-x-1/2 w-96"
            />
            <TwoColumnLayout>
              <Column left>
                <img src={greenArc} className="w-10 mb-10 ml-3" />
                <div className="text-xl font-bold mb-2 ml-3">Tell us more</div>
                <div className="text-sm ml-3 mb-5">
                  Fill this out to join the waitlist.
                </div>
                <SignUpForm
                  mutators={mutators}
                  surveyData={response?.userState?.surveyData}
                />
              </Column>
              <Column right>
                <div className="m-36">
                  <img src={blueHalfArc} />
                  <p>
                    We would love to know more about your use-case. Who knows,
                    maybe we'll move you up the list faster!
                  </p>
                </div>
              </Column>
              <img src={mintWedge} className="absolute left-1/2 bottom-36" />
            </TwoColumnLayout>
          </Body>
          <Footer />
        </Layout>
      </FadeIn>
    );
  }

  return (
    <FadeIn durationMilliseconds={1000}>
      <Layout>
        <Header userState={response.userState} logoutUrl={logoutUrl} />
        <Body>
          <p className="w-6/12 text-xl my-10">
            Welcome to Reboot Cloud. For now, we only have a few things to
            explore but, as we build, this will grow and become your Reboot
            Dashboard.
          </p>
          <img src={greenWedge} className="absolute left-0" />
          <div className="flex justify-center items-center">
            <div className="grid grid-cols-2 gap-16">
              <ButtonLinkCard
                title="Quick start guide"
                description="Get started with Reboot in under 15 minutes!"
                buttonText="TypeScript quickstart"
                linkAddress="https://docs.reboot.dev/get_started/typescript_quickstart"
              />
              <ButtonLinkCard
                title="Discord channel"
                description="Have questions? Feedback? Chat with Reboot developers."
                buttonText="Join the conversation"
                linkAddress="https://discord.gg/cRbdcS94Nr"
              />
              <ButtonLinkCard
                title="Reboot docs"
                description="Everything you need to learn in one place."
                buttonText="Explore Reboot"
                linkAddress="https://docs.reboot.dev"
              />
            </div>
          </div>
        </Body>
        <div className="w-full flex flex-row-reverse">
          <img src={blueArc} className="w-36 my-4 mr-10" />
        </div>
        <Footer />
      </Layout>
    </FadeIn>
  );
};

export function Dashboard() {
  const { session, logoutUrl } = useAuthSession();
  const { bearerToken, setAuthorizationBearer } = useRebootClient();

  useEffect(() => {
    if (session?.tokenized) {
      setAuthorizationBearer(session.tokenized);
    }
  }, [session]);

  if (session?.identity?.id == undefined || bearerToken == undefined) {
    return <></>;
  }

  return (
    <LoggedInDashboard
      userId={session.identity.id}
      userEmail={session.identity.traits.email}
      logoutUrl={logoutUrl}
    />
  );
}
