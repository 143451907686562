import { Column, TwoColumnLayout } from "@/components/ColumnLayout";
import { FadeIn } from "@/components/FadeIn";
import { Session } from "@ory/client";
import { CustomLanguageFormats, locales } from "@ory/elements";
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { sdk, sdkError } from "./ory-sdk";
import background from "/HeroSignUp.svg";
import rebootLogo from "/reboot-logo.svg";

// This index.css is needed to alter the Ory elements that we are using.
// TODO: remove this file and import when we move to our own auth components.
import "./index.css";

export const AuthLayout = ({
  children,
  callToAction,
}: {
  children: React.ReactNode;
  callToAction: string;
}): JSX.Element => {
  return (
    <FadeIn durationMilliseconds={800}>
      <TwoColumnLayout>
        <Column left>
          <div className="flex flex-col md:mr-36 w-[434px]">
            <img src={rebootLogo} className="px-12 mr-20 w-64 mb-6" />
            <h1 className="px-12 font-bold">{callToAction} today</h1>
            <div className="px-12">
              Start building your full stack app today.
            </div>
            <div className="px-12">Run it durably in the cloud tomorrow.</div>
            <div className="ml-16 sm:ml-0">{children}</div>
          </div>
        </Column>
        <Column right>
          <img className="max-h-screen p-4" src={background} />
        </Column>
      </TwoColumnLayout>
    </FadeIn>
  );
};

// Copied and modified from:
// https://github.com/ory/elements/blob/main/examples/react-spa/src/main.tsx#L42
export const customTranslations: CustomLanguageFormats = {
  af: {
    // merging English since no default Afrikaans translations are available
    ...locales.en,
    "login.title": "Meld aan",
    "identities.messages.1070004": "E-posadres",
  },
  en: {
    ...locales.en,
    "login.title": "Login",
    "identities.messages.1070004": "Email",
  },
  nl: {
    ...locales.nl,
    "login.title": "Inloggen",
    "identities.messages.1070004": "E-mail",
  },
};

interface AuthContextProps {
  session: Session | undefined;
  logoutUrl: string | undefined;
  rerunAuthToSession: () => void;
}

const AuthContext = createContext<AuthContextProps>({
  session: undefined,
  logoutUrl: undefined,
  rerunAuthToSession: () => undefined,
});

export const authBasePath = import.meta.env.VITE_APP_ORY_AUTH_ENDPOINT;

function AuthProvider({
  children,
  sessionTokenTimeoutSeconds = 300, // 5 minute timeout default.
}: {
  children: ReactNode;
  sessionTokenTimeoutSeconds: number;
}) {
  const [session, setSession] = useState<Session | undefined>();
  const [logoutUrl, setLogoutUrl] = useState<string>();
  const [runAuthToSession, setRunAuthToSession] = useState<[]>([]);

  // Set runAuthToSession to a new empty array to cause the useEffect below to
  // rerun.
  const rerunAuthToSession = () => setRunAuthToSession([]);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const sdkErrorHandler = sdkError(
    undefined,
    undefined,
    window.location.hostname.includes("signup") || pathname.includes("signup")
      ? "/signup"
      : "/login",
    false
  );

  const createLogoutFlow = () => {
    sdk
      .createBrowserLogoutFlow(undefined, {
        params: {
          return_url: "/",
        },
      })
      .then(({ data }) => setLogoutUrl(data.logout_url))
      .catch(sdkErrorHandler);
  };

  useEffect(() => {
    sdk
      .toSession({ tokenizeAs: "app_reboot_dev_jwk_template" })
      .then(({ data: session }) => {
        setSession(session);
        createLogoutFlow();
      })
      .catch(sdkErrorHandler)
      .catch((error) => {
        console.warn("HERE IS THE ERROR: ", JSON.stringify(error));
        if (error.message) {
          return navigate(
            `/error?error=${encodeURIComponent(error.message)}&from=auth1`,
            {
              replace: true,
            }
          );
        }
        return navigate(
          `/error?error=${encodeURIComponent(
            JSON.stringify(error)
          )}&from=auth2`,
          {
            replace: true,
          }
        );
      });
  }, [runAuthToSession]);

  useEffect(() => {
    // NOTE: session timeout and token timeout are different. By default Ory's
    // sessions timeout in 30 days. Default session tokens timeout is 10
    // minutes.
    const refresh_auth_token = setInterval(() => {
      rerunAuthToSession();
    }, sessionTokenTimeoutSeconds * 1000);

    return () => clearInterval(refresh_auth_token);
  }, []);

  return (
    <AuthContext.Provider
      value={{ session, logoutUrl, rerunAuthToSession }}
      key="AuthContext"
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.defaultProps = { sessionTokenTimeoutSeconds: 300 };

const useAuthSession = () => useContext(AuthContext);

export { AuthProvider, useAuthSession };
