// Copyright © 2023 Ory Corp
// SPDX-License-Identifier: Apache-2.0
// This file was copied and modified from:
// https://github.com/ory/elements/blob/main/examples/react-spa/src/sdk.ts
// Ory custom UI elements documentation:
// https://www.ory.sh/docs/kratos/bring-your-own-ui/custom-ui-ory-elements
//
// This file doesn't play nicely with our version of the TS compiler because
// most of this file was pulled from the reference above and it was likely
// compiled with a different version of TS. We assume this code will work as
// it is the API that Ory provides. That's a pretty large assumption so this
// is the ticket that tracks that assumption: #2463
// @ts-nocheck
import { Configuration, FrontendApi } from "@ory/client";
import { AxiosError } from "axios";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const sdk = new FrontendApi(
  new Configuration({
    basePath: import.meta.env.VITE_APP_ORY_AUTH_ENDPOINT,
    baseOptions: {
      withCredentials: true,
    },
  })
);

/**
 * @param getFlow - Should be function to load a flow make it visible (Login.getFlow)
 * @param setFlow - Update flow data to view (Login.setFlow)
 * @param defaultNav - Default navigate target for errors
 * @param fatalToDash - When true and error can not be handled, then redirect to dashboard, else rethrow error
 */
export const sdkError = (
  getFlow: ((flowId: string) => Promise<void | AxiosError>) | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFlow: React.Dispatch<React.SetStateAction<any>> | undefined,
  defaultNav: string | undefined,
  fatalToDash = false
) => {
  const navigate = useNavigate();

  return useCallback(
    (error: AxiosError): Promise<AxiosError | void> => {
      console.warn("ORY IS CONSIDERING AN ERROR: ", JSON.stringify(error));
      const responseData = error.response?.data || {};

      console.warn("WITH CODE: ", error.response?.status);
      switch (error.response?.status) {
        case 400: {
          if (error.response.data?.error?.id === "session_already_available") {
            navigate("/", { replace: true });
            return Promise.resolve();
          }
          if (setFlow !== undefined) {
            setFlow(responseData);
            return Promise.resolve();
          }
          break;
        }
        case 401: {
          console.warn("401 error");
          navigate(defaultNav, { replace: true });
          return Promise.resolve();
        }
        case 403: {
          if (responseData.error?.id === "session_aal2_required") {
            navigate(`/${defaultNav}?aal2=true`, { replace: true });
            return Promise.resolve();
          }

          if (
            responseData.error?.id === "session_refresh_required" &&
            responseData.redirect_browser_to
          ) {
            window.location = responseData.redirect_browser_to;
            return Promise.resolve();
          }
          break;
        }
        case 404: {
          if (defaultNav !== undefined) {
            const errorMsg = {
              data: error.response?.data || error,
              status: error.response?.status,
              statusText: error.response?.statusText,
              url: window.location.href,
            };

            navigate(
              `/error?error=${encodeURIComponent(
                JSON.stringify(errorMsg)
              )}&from=ory-404`,
              {
                replace: true,
              }
            );
            return Promise.resolve();
          }
          break;
        }
        case 410: {
          if (getFlow !== undefined && responseData.use_flow_id !== undefined) {
            return getFlow(responseData.use_flow_id).catch((error) => {
              if (defaultNav !== undefined) {
                navigate(defaultNav, { replace: true });
              } else {
                throw error;
              }
            });
          } else if (defaultNav !== undefined) {
            navigate(defaultNav, { replace: true });
            return Promise.resolve();
          }
          break;
        }
        case 422: {
          if (responseData.redirect_browser_to !== undefined) {
            const currentUrl = new URL(window.location.href);
            const redirect = new URL(
              responseData.redirect_browser_to,
              window.location.origin
            );

            if (currentUrl.pathname !== redirect.pathname) {
              redirect.pathname = redirect.pathname.replace("/ui", "");
              navigate(redirect.pathname + redirect.search, {
                replace: true,
              });
              return Promise.resolve();
            }

            const flowId = redirect.searchParams.get("flow");

            if (flowId != null && getFlow !== undefined) {
              return getFlow(flowId).catch((error) => {
                if (defaultNav !== undefined) {
                  navigate(defaultNav, { replace: true });
                } else {
                  throw error;
                }
              });
            } else {
              window.location = responseData.redirect_browser_to;
              return Promise.resolve();
            }
          }
        }
      }

      if (fatalToDash) {
        navigate("/", { replace: true });
        return Promise.resolve();
      }

      console.log("Error remains unhandled");

      throw error;
    },
    [navigate, getFlow]
  );
};
