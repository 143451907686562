// @generated by protoc-gen-es v1.3.2
// @generated from file rbt/cloud/v1alpha1/user/user.proto (package rbt.cloud.v1alpha1.user, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { TaskId } from "../../../v1alpha1/tasks_pb.js";

/**
 * @generated from message rbt.cloud.v1alpha1.user.SurveyData
 */
export const SurveyData = proto3.makeMessageType(
  "rbt.cloud.v1alpha1.user.SurveyData",
  () => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "company_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "preferred_programming_languages", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "use_case", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "about_yourself", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message rbt.cloud.v1alpha1.user.User
 */
export const User = proto3.makeMessageType(
  "rbt.cloud.v1alpha1.user.User",
  () => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "survey_data", kind: "message", T: SurveyData },
    { no: 3, name: "status", kind: "enum", T: proto3.getEnumType(User_Status) },
    { no: 4, name: "signup_mailgun_message_id_prefix", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "activation_mailgun_message_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "api_key_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from enum rbt.cloud.v1alpha1.user.User.Status
 */
export const User_Status = proto3.makeEnum(
  "rbt.cloud.v1alpha1.user.User.Status",
  [
    {no: 0, name: "WAITLIST"},
    {no: 1, name: "ACTIVE"},
    {no: 2, name: "INACTIVE"},
  ],
);

/**
 * @generated from message rbt.cloud.v1alpha1.user.Users
 */
export const Users = proto3.makeMessageType(
  "rbt.cloud.v1alpha1.user.Users",
  () => [
    { no: 1, name: "user_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message rbt.cloud.v1alpha1.user.AddRequest
 */
export const AddRequest = proto3.makeMessageType(
  "rbt.cloud.v1alpha1.user.AddRequest",
  () => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message rbt.cloud.v1alpha1.user.AddResponse
 */
export const AddResponse = proto3.makeMessageType(
  "rbt.cloud.v1alpha1.user.AddResponse",
  [],
);

/**
 * @generated from message rbt.cloud.v1alpha1.user.InvalidInputError
 */
export const InvalidInputError = proto3.makeMessageType(
  "rbt.cloud.v1alpha1.user.InvalidInputError",
  () => [
    { no: 1, name: "reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message rbt.cloud.v1alpha1.user.SignUpRequest
 */
export const SignUpRequest = proto3.makeMessageType(
  "rbt.cloud.v1alpha1.user.SignUpRequest",
  () => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message rbt.cloud.v1alpha1.user.SignUpResponse
 */
export const SignUpResponse = proto3.makeMessageType(
  "rbt.cloud.v1alpha1.user.SignUpResponse",
  [],
);

/**
 * @generated from message rbt.cloud.v1alpha1.user.SetSurveyDataRequest
 */
export const SetSurveyDataRequest = proto3.makeMessageType(
  "rbt.cloud.v1alpha1.user.SetSurveyDataRequest",
  () => [
    { no: 1, name: "survey_data", kind: "message", T: SurveyData },
  ],
);

/**
 * @generated from message rbt.cloud.v1alpha1.user.SetSurveyDataResponse
 */
export const SetSurveyDataResponse = proto3.makeMessageType(
  "rbt.cloud.v1alpha1.user.SetSurveyDataResponse",
  [],
);

/**
 * @generated from message rbt.cloud.v1alpha1.user.SetStatusRequest
 */
export const SetStatusRequest = proto3.makeMessageType(
  "rbt.cloud.v1alpha1.user.SetStatusRequest",
  () => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(User_Status) },
  ],
);

/**
 * @generated from message rbt.cloud.v1alpha1.user.SetStatusResponse
 */
export const SetStatusResponse = proto3.makeMessageType(
  "rbt.cloud.v1alpha1.user.SetStatusResponse",
  () => [
    { no: 3, name: "activate_task_id", kind: "message", T: TaskId, opt: true },
  ],
);

/**
 * @generated from message rbt.cloud.v1alpha1.user.ActivateResponse
 */
export const ActivateResponse = proto3.makeMessageType(
  "rbt.cloud.v1alpha1.user.ActivateResponse",
  [],
);

/**
 * @generated from message rbt.cloud.v1alpha1.user.GetRequest
 */
export const GetRequest = proto3.makeMessageType(
  "rbt.cloud.v1alpha1.user.GetRequest",
  [],
);

/**
 * @generated from message rbt.cloud.v1alpha1.user.GetResponse
 */
export const GetResponse = proto3.makeMessageType(
  "rbt.cloud.v1alpha1.user.GetResponse",
  () => [
    { no: 1, name: "user_state", kind: "message", T: User },
  ],
);

